import { useEffect, useState } from "react"
import { Container, Grid, Header, Segment, Image, Transition } from "semantic-ui-react"
import frame from '../../Assets/Images/Frame.png'

const HiringHardSection = (props) =>{
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    useEffect(()=>{
        if(props.isAnimated){
            setTimeout(()=>{
                setShow2(true)
            }, 500)
    
            setTimeout(()=>{
                setShow(true)
            }, 1000)
        }
    })

    return(
        <Segment basic className="hiringHardWrapper">
            <Container>
                <Grid columns={'equal'}>
                    <Grid.Column>
                        <Transition visible={show} animation="fade right" duration={1000}>
                            <div>
                            <Header as="h1">HIRING IS HARD</Header>
                            <p>It is almost impossible to interview for soft skills like grit, motivation and diligence from psychometric tests or through an interview.</p>
                            <p>To hire effectively from developing countries cost efficiently, the ability to assess English skills accurately is important.</p>
                            </div>
                        </Transition>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <Transition visible={show2} animation="fade down" duration={1000}>
                            <div>
                                <Image src={frame} size="medium"/>
                            </div>
                        </Transition>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>
    )
}

export default HiringHardSection