import { useEffect, useState } from "react"
import { Container, Grid, Header, Segment, Image, Transition } from "semantic-ui-react"
import frame from '../../Assets/Images/Frame-3.png'
import frame2 from '../../Assets/Images/Frame-4.png'

const BestCandidateSection = (props) =>{
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    useEffect(()=>{
        if(props.isAnimated){
            setTimeout(()=>{
                setShow(true)
            }, 500)
    
            setTimeout(()=>{
                setShow2(true)
            }, 1500)
        }
    })
    return(
        <Segment basic className="bestCandidateWrapper">
            <Header as="h1" textAlign="center">PICK THE BEST CANDIDATES</Header>
           <Container>
               <Grid columns={'equal'}>
                   <Transition visible={show} duration={1000} animation="fade up">
                   <Grid.Row>
                        <Grid.Column/>
                        <Grid.Column width={3}>
                                <Image src={frame}/>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <p>Based on <b>&gt;3M</b> datapoints collected over the last 5 years, we can assess the 6 sub skills of English accurately: reading, writing, vocab, grammar, listening, speaking.</p>
                        </Grid.Column>
                        <Grid.Column/>
                   </Grid.Row>
                   </Transition>
                   <Transition visible={show2} duration={1000} animation="fade up">
                   <Grid.Row>
                        <Grid.Column/>
                        <Grid.Column width={3}>
                                <Image src={frame2}/>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <p>On top of that, we can identify the most gritty, diligent and motivated candidates. These are strong success skills that would help them thrive in your organization.</p>
                        </Grid.Column>
                        <Grid.Column/>
                   </Grid.Row>
                   </Transition>
                   
               </Grid>
           </Container>
        </Segment>
    )
}

export default BestCandidateSection