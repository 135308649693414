import { useEffect, useState } from "react"
import { Segment, Container, Header, Image, Grid, Transition } from "semantic-ui-react"
import frame from '../../Assets/Images/Frame-2.png'

const WinTalentSection = (props) =>{
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    useEffect(()=>{
        if(props.isAnimated){
            setTimeout(()=>{
                setShow(true)
            }, 500)
    
            setTimeout(()=>{
                setShow2(true)
            }, 1000)
        }
    })

    return(
        <Segment basic className="winTalentWrapper">
            <Container>
                <Grid columns={'equal'}>
                    <Grid.Column>
                        <Transition visible={show} animation="fade right" duration={1000}>
                            <div>
                            <Image src={frame}/>
                            </div>
                        </Transition>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Transition visible={show2} animation="fade up" duration={1000}>
                            <div>
                            <Header as="h1">WIN THE TALENT WAR</Header>
                            <p>Building a strong talent pool is the main competitive advantage in the knowledge based economy.</p>
                            <p>We help you recruit, filter and assess your candidates, so that you can focus on your business.</p>
                            </div>
                        </Transition>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>
    )
}

export default WinTalentSection