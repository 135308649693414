import React from 'react'
import { Grid, Segment } from "semantic-ui-react"
import { WHATSAPP_LINK } from '../../utils/constant'

const RecruiterSnippet = () => {
    return <Segment basic >
        <Grid>
            <Grid.Row className='recruiterSnippetWrappper'>
                <div className='spacer-small'>
                    <p className='grey'>Are you a recruiter?</p>
                </div>

                <a target="_blank" href={WHATSAPP_LINK + '?text=Hello%2C%20I%E2%80%99m%20looking%20to%20hire%20with%20Solve%20Education.'}
                    className="ui solveed-teal button border-2">Post A Job!</a>
            </Grid.Row>
        </Grid>
    </Segment>
}

export default RecruiterSnippet