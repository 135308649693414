import { Header, Segment, Image } from "semantic-ui-react"
import Slider from "react-slick";
import g1 from '../../Assets/Images/g1.png'
import g2 from '../../Assets/Images/g2.png'
import g3 from '../../Assets/Images/g3.png'
import g4 from '../../Assets/Images/g4.png'
import g5 from '../../Assets/Images/g5.png'

const GalleryLASection = () =>{
    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 500,
        draggable: false,
        focusOnSelect: false,
        responsive:[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
              }
            },
            {
              breakpoint: 820,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    const galleryList = [
        {
            title: "Assess accurately",
            image: g1
        },
        {
            title: "Evaluate soft skills",
            image: g5
        },
        {
            title: "Customize your assessment",
            image: g3
        },
        {
            title: "Effective filtering",
            image: g2
        },
        {
            title: "Leverage on technology",
            image: g4
        }
    ]

    return(
        <Segment basic className="galleryLAWrapper">
            <Slider {...settings}>
                {galleryList.map((v, k)=>
                <Segment basic key={k}>
                    <Header as="h2">{v.title}</Header>
                    <Image src={v.image} centered/>
                </Segment>
                )}
            </Slider>
        </Segment>
    )
}

export default GalleryLASection