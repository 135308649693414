import { Container, Grid, Segment, Image, Header, Transition } from "semantic-ui-react"
import frame from '../../Assets/Images/Frame-6.png'
import frame2 from '../../Assets/Images/Frame-5.png'
import frame3 from '../../Assets/Images/Frame-1.png'
import { useEffect, useState } from "react"

const BenefitSection = (props) =>{
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)

    useEffect(()=>{
        if(props.isAnimated){
            setTimeout(()=>{
                setShow(true)
            }, 500)
    
            setTimeout(()=>{
                setShow2(true)
            }, 1000)
    
            setTimeout(()=>{
                setShow3(true)
            }, 1500)
        }
    })
    return(
        <Segment basic className="benefitWrapper">
            <Container>
                <Grid columns={'equal'} className="benefitGrid">
                    <Transition visible={show} duration={1000} animation="fade left">
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Image src={frame}/>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column>
                            <Header as="h1">
                                RECRUITING / FILTERING / ASSESSING
                            </Header>
                            <p>We take on the heavy lifting of recruiting, filtering and assessing</p>
                        </Grid.Column>
                    </Grid.Row>
                    </Transition>
                    <Transition visible={show2} duration={1000} animation="fade right">
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header as="h1">
                                LEVERAGE ON TECHNOLOGY
                            </Header>
                            <p>We leverage on technology to make informed decisions</p>
                        </Grid.Column>
                        <Grid.Column width={2}/>
                        <Grid.Column width={4}>
                            <Image src={frame2} id="secondBenefit"/>
                        </Grid.Column>
                    </Grid.Row>
                    </Transition>
                    <Transition visible={show3} duration={1000} animation="fade left">
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Image src={frame3}/>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column>
                            <Header as="h1">
                                TIME & COST SAVINGS
                            </Header>
                            <p>You save on recruitment costs</p>
                        </Grid.Column>
                    </Grid.Row>
                    </Transition>
                </Grid>
            </Container>
        </Segment>
    )
}

export default BenefitSection