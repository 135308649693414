import { useEffect, useState } from "react"
import { Container, Header, Segment, Image, Transition } from "semantic-ui-react"
import logo from '../../Assets/Images/logo.svg'
const BannerSection = (props) => {
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    useEffect(() => {
        if (props.isAnimated) {
            setTimeout(() => {
                setShow(true)
            }, 500)

            setTimeout(() => {
                setShow2(true)
            }, 1500)
        }
    })

    return (
        <Segment basic className="bannerWrapper">
            <Container>
                <Image src={logo} size="tiny" />
            </Container>
            <Container>
                <Transition visible={show} animation="fade left" duration={1000}>
                    <Header as="h1">
                        Looking for A Job?
                        Discover opportunities with Solve Education! where a catch meet a match

                    </Header>
                </Transition>
                <Transition visible={show2} animation="slide down" duration={1000}>
                    <div>
                        <a href={'opportunity'} className="ui button huge primary" >
                            Get Hired Now
                            <i class="arrow right icon"></i>
                        </a>
                    </div>
                </Transition>
            </Container>
        </Segment>
    )
}

export default BannerSection