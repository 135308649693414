export const AccordionData = [
    {
        title: "F&B Industry",
        summary: "Solve Education is here to assist you in growing your career. If you have the passion and aptitude for food and service, the F&B sector might be your perfect career choice. Work in this industry can range from packaging to preparing, transporting, and serving food or beverages for guests.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20F%26B%20industry%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Sales and Marketing",
        summary: "Your primary responsibilities in Sales and Marketing will revolve around driving business growth. You'll strategize and execute marketing campaigns, generate leads, and nurture customer relationships.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20sales%20and%20marketing%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Administration",
        summary: "In administration, your key responsibilities include managing schedules, handling correspondence, organizing meetings, maintaining records, and providing essential support to teams.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20administration%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Human Resources",
        summary: "In Human Resources, your central focus is on the people who power the organization. You'll be responsible for recruiting, onboarding, and developing talent, as well as managing employee relations and benefits.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20Human%20Resources%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Finance and Accounting",
        summary: "In Finance and Accounting, your primary responsibilities include managing budgets, financial reporting, and ensuring compliance with financial regulations.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20finance%20and%20accounting%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Information Technology",
        summary: "Your key responsibilities in Information Technology include troubleshooting technical issues, ensuring network security, and implementing and maintaining software, and hardware features/systems.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20information%20technology%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Project Manager",
        summary: "Project Managers are responsible for planning, executing, and overseeing projects from inception to completion. Key responsibilities include defining project goals, assembling and leading teams, managing budgets, and ensuring timelines are met.",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20the%20project%20manager%20job%20category%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    },
    {
        title: "Others",
        summary: "Can't find your ideal position? We've got more up our sleeve. Connect with our specialist to find a match!",
        whatsapp: "Hello%2C%20I%27m%20interested%20in%20applying%20to%20other%20job%20categories%20through%20Solve%20Education.%20What%20are%20the%20next%20steps%3F"
    }
]