import LandingPage from "./Pages/LandingPage";
import OpportunityPage from "./Pages/Opportunity/Opportunity";
import { Routes, Route } from "react-router-dom";
import './style.scss'

function App() {
  return (
    <div id="solveEmploymentRoot">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/opportunity" element={<OpportunityPage />} />
      </Routes>

    </div>
  );
}

export default App;
