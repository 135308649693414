import { Container, Segment, Divider } from "semantic-ui-react"
import { WHATSAPP_LINK } from "../../utils/constant"

const JoinUsSection = () => {

    return (
        <Segment basic className="joinUsWrapper">
            <Container textAlign="center">
                <p>Looking forward to contributing to your success!<br />Outsource your recruitment to us and let us help you find the right talents!</p>
                <Divider hidden />
                <Divider hidden />
                <a
                    target="_blank"
                    className="ui button primary huge"
                    href={WHATSAPP_LINK + '?text=Hello%2C%20I%E2%80%99m%20looking%20to%20hire%20with%20Solve%20Education.'}>
                    Post A Job!
                </a>
            </Container>
        </Segment>
    )
}

export default JoinUsSection