import { Container, Divider, Grid, Header, Icon, List, Segment, Image, Modal, TransitionablePortal, Button } from "semantic-ui-react"
import logo from '../../Assets/Images/logo_color.svg'
import { useState } from "react"
import TermsOfServicePage from "../Legal/TermsOfService"
import PrivacyPolicyPage from "../Legal/PrivacyPolicy"
import { Link } from "react-router-dom"
const FooterSection = () => {
    const [modalShow, setModalShow] = useState(false)
    const [selectedType, setSelectedType] = useState('')
    const currentYear = new Date().getFullYear()
    const openLegal = (type, e) => {
        setSelectedType(type)
        setModalShow(true)
    }

    return (
        <div>
            <Segment basic className="footerWrapper">
                <Container>
                    <Grid columns={'equal'}>
                        <Grid.Column>
                            <Image src={logo} />
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h3">
                                QUICK LINKS
                            </Header>
                            <List link>
                                <List.Item as={'a'} href="https://dawnofcivilization.net/" target="_blank">Dawn of Civilization</List.Item>
                                <List.Item as={'a'} href="https://learnalytics.solveeducation.org/" target="_blank">Learnalytics</List.Item>
                                <List.Item as={'a'} href="https://content.solveeducation.org/" target="_blank">Content+</List.Item>
                                <List.Item as={'a'} href="/">Solve Employment</List.Item>
                                <List.Item as={'a'} href="https://edbot.ai" target="_blank">Ed the Learning Bot</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h3">
                                LEGAL
                            </Header>
                            <List link>
                                <List.Item as={'a'} onClick={openLegal.bind(null, "tos")}>Terms of Service</List.Item>
                                <List.Item as={'a'} onClick={openLegal.bind(null, "policy")}>Privacy Policy</List.Item>
                            </List>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Segment>
            <Segment basic className="footer2Wrapper">
                <Container>
                    <Grid columns={'equal'}>
                        <Grid.Column>
                            <span>© {currentYear} Solve Employment</span>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Link to={'https://www.facebook.com/solveeducation/'} target="_blank">
                                <Icon name="facebook f" />
                            </Link>
                            <Link to={'https://www.facebook.com/solveeducation/'} target="_blank">
                                <Icon name="instagram" />
                            </Link>
                            
                        </Grid.Column>
                    </Grid>
                </Container>
            </Segment>

            <TransitionablePortal open={modalShow} onClose={() => setModalShow(false)}>
                <Modal size="small" open={modalShow} onClose={() => setModalShow(false)} closeIcon>
                    <Modal.Header>
                        {selectedType === 'tos' ? <>
                            <Icon name="list" color="orange" /> Terms of Service
                        </> : <>
                            <Icon name="shield" color="orange" /> Privacy Policy
                        </>}
                    </Modal.Header>
                    <Modal.Content scrolling>
                        {selectedType === 'tos' ? <TermsOfServicePage /> : <PrivacyPolicyPage />}
                    </Modal.Content>
                </Modal>
            </TransitionablePortal>
        </div>

    )
}

export default FooterSection