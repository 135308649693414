import React, { useState } from 'react'
import logo from '../../Assets/Images/logo.svg'
import { Grid, Image, Header, Accordion } from 'semantic-ui-react'
import { AccordionData } from './data'
import FooterSection from '../LandingPage/Footer'
import { WHATSAPP_LINK } from '../../utils/constant'
import { Link } from 'react-router-dom'

const OpportunityPage = () => {
    const [activeIndexAccordion, setActiveIndexAccordion] = useState(0)



    const onAccordionExpandClick = (index) => {
        const newIndex = activeIndexAccordion === index ? -1 : index
        setActiveIndexAccordion(newIndex)
    }
    return <div id='opportunity'>
        <div id='opportunityPage'>
            <Grid padded columns={'equal'} stretched className='opportunityToolbarWrapper'>
                <Grid.Column className='opportunityToolbar'>
                    <a href='/' className='opportunity-logo-wrapper'>
                        <Image src={logo} size='tiny' />
                    </a>
                </Grid.Column>
                <Grid.Column />
                <Grid.Column>
                    <Grid>
                        <Grid.Row className='recruiterSnippetWrappper'>
                            <div className='spacer-small'>
                                <p className='grey'>Are you a recruiter?</p>
                            </div>
                            <Link target="_blank" className="ui solveed-teal button border-2" to={WHATSAPP_LINK + '?text=Hello%2C%20I%E2%80%99m%20looking%20to%20hire%20with%20Solve%20Education.'}>
                            Post A Job!
                            </Link>
                        </Grid.Row>
                    </Grid>

                </Grid.Column>
            </Grid>




            <div className='opportunityHeaderWrapper'>
                <div className='top-wrapper'>
                    <Header as="h1">
                        Join Our Talent Network
                    </Header>
                    <p>Are you ready to embark on an exciting career journey? We invite you to become<br></br> part of our Talent Network, where opportunities await!</p>
                </div>


                <Grid padded centered className='center-self'>
                    <Grid.Column width={12}>
                        <div className='ui column grid divided rounded-wrapper'>
                            <Accordion>
                                {

                                    AccordionData.map((d, index) => (
                                        <Grid.Column stretched={true} key={index}>
                                            <Accordion.Title index={index} onClick={() => onAccordionExpandClick(index)}
                                                active={activeIndexAccordion === index}
                                            >
                                                {
                                                    activeIndexAccordion === index ? <i className="large caret down icon"></i> :
                                                        <i className="large caret right icon"></i>
                                                }


                                                {d.title}
                                            </Accordion.Title>
                                            <Accordion.Content
                                                className='expand'
                                                active={activeIndexAccordion === index}>
                                                <div>
                                                    <div className={"summary content-margin"}>
                                                        <p>{d.summary}</p>
                                                    </div>
                                                    <div className='contact-button'>
                                                        <Link target="_blank"
                                                            className="ui button green huge"
                                                            to={WHATSAPP_LINK +'?text=' + d.whatsapp}>
                                                        <i className="whatsapp icon"></i>
                                                            Get in Touch
                                                        </Link>
                                                    </div>
                                                </div>

                                            </Accordion.Content>
                                            {index === AccordionData.length - 1 ? null : <div className="ui clearing divider"></div>}



                                        </Grid.Column>
                                    ))
                                }


                            </Accordion>
                        </div>
                    </Grid.Column>
                </Grid>

            </div>
        </div>
        <section id="footer">
            <FooterSection />
        </section>
    </div>


}


export default OpportunityPage