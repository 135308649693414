import BannerSection from './Banner'
import BenefitSection from './Benefit'
import BestCandidateSection from './BestCandidate'
import FooterSection from './Footer'
import GalleryLASection from './GalleryLA'
import HeaderSection from './Header'
import HiringHardSection from './HiringHard'
import JoinUsSection from './JoinUs'
import RecruiterSnippet from './RecruiterSnippet'
import WinTalentSection from './WinTalent'
import { useCallback, useEffect, useState } from 'react'

const LandingPage = () => {
    const [y, setY] = useState(window.scrollY);
    const [animated, setAnimated] = useState(false)
    const [animated2, setAnimated2] = useState(false)
    const [animated3, setAnimated3] = useState(false)
    const [animated4, setAnimated4] = useState(false)
    const [animated5, setAnimated5] = useState(false)
    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY) {
                arrangeActiveIndex(window.scrollY)
            } else if (y < window.scrollY) {
                arrangeActiveIndex(window.scrollY)
            }
            setY(window.scrollY);
        }, [y] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const arrangeActiveIndex = (y) => {
        let m1 = document.getElementById('banner').offsetTop - 80
        let m2 = document.getElementById('galleryLA').offsetTop - 80
        let m3 = document.getElementById('hiringHard').offsetTop - 80
        let m4 = document.getElementById('winTalent').offsetTop - 80
        let m5 = document.getElementById('bestCandidate').offsetTop - 80
        let m6 = document.getElementById('benefit').offsetTop - 80
        let m7 = document.getElementById('joinUs').offsetTop - 80

        if (y >= m1 && y < m2 && !animated) {
            setAnimated(true)
        }

        if (y >= m3 / 2 && y < m4 && !animated2) {
            setAnimated2(true)
        }

        if (y >= m4 / 2 + 100 && y < m5 && !animated3) {
            setAnimated3(true)
        }

        if (y >= m5 / 2 + 400 && y < m6 && !animated4) {
            setAnimated4(true)
        }

        if (y >= m6 - 400 && y < m7 && !animated5) {
            setAnimated5(true)
        }

    }

    useEffect(() => {
        setY(window.scrollY);
        arrangeActiveIndex(window.scrollY)
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="landingPage">
            <section id="header">
                <HeaderSection />
            </section>
            <section id="banner">
                <BannerSection isAnimated={animated} />
            </section>
            <section>
                <RecruiterSnippet />
            </section>
            <section id="galleryLA">
                <GalleryLASection />
            </section>
            <section id="hiringHard">
                <HiringHardSection isAnimated={animated2} />
            </section>
            <section id="winTalent">
                <WinTalentSection isAnimated={animated3} />
            </section>
            <section id="bestCandidate">
                <BestCandidateSection isAnimated={animated4} />
            </section>
            <section id="benefit">
                <BenefitSection isAnimated={animated5} />
            </section>
            <section id="joinUs">
                <JoinUsSection />
            </section>
            <section id="footer">
                <FooterSection />
            </section>
        </div>
    )
}

export default LandingPage